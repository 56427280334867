import React, { useCallback, useEffect, useReducer } from 'react';
import { useMutation } from 'react-query';
import { put } from '../api/_helper';
import { Insurance } from '../type.ts';

import { useGetPatient } from './useGetPatient';
import { healthCardValidationSchema } from '../validationSchemas/healthCardValidationSchema';

const getUrl = (patientId: any) => `apiv2/pt/patients/${patientId}/insurance`;

export const useGetInsurance = () => {
	const { data: patient, ...payload } = useGetPatient();
	// Ensure that the most-recently-updated insurance comes first, with updatedAt descending.
	// This is not a problem for Canada, where patients can only have on active insurance.
	// However, Product will spend some time considering what UX and behavior they want, for the USA,
	// where patients can have more than one active health insurance. For now, we treat the US patients
	// like Canadian patients -- only the most-recently-updated insurance is displayed and edited.
	const patientInsurances = patient?.insurances?.sort((a, b) =>
		// @ts-ignore
		b.updatedAt.toString().localeCompare(a.updatedAt.toString()),
	);

	return {
		...payload,
		patientId: patient?.id,
		insurances: patientInsurances,
	};
};

const mutateDataReducer = (prevState: Partial<Insurance>, payload: Partial<Insurance>) => {
	return {
		...prevState,
		...payload,
	};
};

const patientValidationReducer = (
	prevState: Partial<Record<keyof Insurance, any>>,
	payload: Partial<Record<keyof Insurance, any>>,
) => {
	return {
		...prevState,
		...payload,
	};
};

const saveInsurance = ({ patientId, insurance }: { patientId: any; insurance: Partial<Insurance> }) =>
	put(getUrl(patientId), insurance);

export const useMutateInsurance = (options?: Record<any, any>) => {
	const {
		//@ts-ignore
		insurances: [insuranceData],
		patientId,
		isFetching,
	} = useGetInsurance();

	const [insurance, updateInsurance] = useReducer(mutateDataReducer, insuranceData);
	const [insuranceValidation, updatePatientValidation] = useReducer(patientValidationReducer, {});

	const { mutate, isLoading: isSaving } = useMutation(saveInsurance, options);

	const saveChanges = useCallback(() => {
		mutate({
			patientId,
			insurance,
		});
	}, [insurance, mutate]);

	useEffect(() => {
		updateInsurance(insuranceData);
	}, [insuranceData]);

	const onFieldChange = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: any } }) => {
			const {
				target: { name, value },
			} = evt;

			// @ts-ignore
			const fieldValidationSchema = healthCardValidationSchema.pick([name]);

			const payload = { [name]: value };

			console.log('[useMutateInsurance]', 'onFieldChange', payload);

			fieldValidationSchema
				.validate(payload)
				.then(() => {
					console.log('[useMutateInsurance]', 'onFieldChange', 'Validation:pass', { name });
					updatePatientValidation({ [name]: null });
				})
				.catch((err: Error) => {
					console.log('[useMutateInsurance]', 'onFieldChange', 'Validation:catch', { [name]: err });

					updatePatientValidation({
						[name]: err,
					});
				});

			updateInsurance(payload);
		},
		[],
	);

	return {
		onFieldChange,

		insurance,
		validation: insuranceValidation,

		saveChanges,

		isSaving,
		isFetching,
	};
};
